<template>
    <div>
        <loading :active="isLoading"
                 :can-cancel="false"
                 :is-full-page="fullPage"></loading>
        <nav class="navbar navbar-expand-lg navbar-dark">
            <div class="container-xl">
                <div class="navbar-brand">實聯快疫通<small class="ml-3">Speed Pass</small></div>
                <!--m_menu-->
                <button v-if="isUserMenuActive" class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample07XL" aria-controls="navbarsExample07XL" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <!--end m_menu-->

                <div v-if="isUserMenuActive" class="collapse navbar-collapse" id="navbarsExample07XL">
                    <ul class="navbar-nav mr-auto">
                        <li class="nav-item">
                            <a class="nav-link" href="#" v-on:click="enableUpdateMode">修改實名資料</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>

        <div class="container pb-5">
            <div class="row pt-5">

                <div class="col-md-8 order-md-1 mb-4">
                    <h4 class="mb-3">{{locationName}} - 實聯制登記表<br>到店時間：{{ this.moment(new Date()).format('YYYY/MM/DD HH:mm:ss') }}</h4>

                    <form class="needs-validation" @submit.prevent="submit" novalidate>

                        <div class="form-group">
                            <label for="username">姓名 Name</label>
                            <input type="text" class="form-control" id="username" placeholder="請輸入姓名" v-model="userName" required v-bind:readonly="this.isUserMenuActive && this.status !== 'UserProfileUpdate'"><!--有錯誤的時候 class 加上is-invalid 就可以-->
                            <small v-if="!isUserMenuActive" id="usernameHelp" class="form-text text-muted">請填寫與身份證明文件上相同之名稱，以備入場需核對身份證明時使用。</small>
                            <div class="invalid-feedback">請輸入姓名</div>
                        </div>

                        <div class="form-group">
                            <label for="userphone">手機號碼 Phone Number</label>
                            <input type="tel" pattern="^(\d+-?)+\d+$" id="userphone" class="form-control" placeholder="請輸入手機號碼" v-model="userPhoneNumber" required v-bind:readonly="this.isUserMenuActive && this.status !== 'UserProfileUpdate'">
                            <small id="userphoneHelp" class="form-text text-muted">請填寫最少28日內可聯繫到您的手機號碼</small>
                            <div class="invalid-feedback">請輸入手機號碼</div>
                        </div>

                        <div class="form-group">
                            <label for="usernumber">同行人數(含您自己) Number of people entering </label>
                            <input type="tel" class="form-control" id="usernumber" placeholder="請輸入同行人數" v-model="attendance" required><!--有錯誤的時候 class 加上is-invalid 就可以-->
                            <div class="invalid-feedback">請務必輸入同行人數</div>
                        </div>

                        <button class="btn btn-primary btn-lg btn-block mt-4" type="submit">{{'確認'}}</button>
                    </form>
                </div>

                <div class="col-md-4 order-md-2">
                    <h4 class="mb-3">說明</h4>
                    <p class="small">
                        實聯快疫通Speed Pass<br>
                        因應疫情防治之個人資料保護權益宣告<br>

                        1. 特定目的<br>
                        本服務基於「嚴重特殊傳染性肺炎」疫情防治相關業務所需，取得您的個人資料。<br>

                        2. 蒐集個人資料之類別<br>
                        個人資料包括姓名、行動電話等，或其他得以直接或間接識別您個人之資料，詳如填寫表內容。<br>

                        3. 利用期間、地區、對象及方式<br>
                        (1)期間：將於蒐集目的執行至結束所須之保存期間。以自蒐集日起 28 日後需刪除，並留存刪除日期紀錄。<br>
                        (2)地區：中華民國地區及符合蒐集目的之對象所在地區。<br>
                        (3)利用者：場所營業單位與實聯快疫通Speed Pass 。<br>
                        (4)利用方式：在不違反蒐集目的與為防堵疫情而有必要時，得提供衛生主管機關依傳染病防治法等規定進行疫情調查及聯繫使用，以網際網路、電子郵件、書面、傳真及其他合法方式利用之。<br>


                        4. 您可依個人資料保護法，就您的個人資料向本單位<br>
                        (1)請求查詢或閱覽。<br>
                        (2)製給複製本。<br>
                        (3)請求補充或更正。<br>
                        (4)請求停止蒐集、處理及利用。<br>
                        (5)請求刪除。<br>

                        5. 如欲行使前項權利，請來信至info@yscreatech.com<br>
                        6. 個人資料之保密對實聯快疫通Speed Pass 服務，即”揚揚創新科技有限公司"(下稱本單位)，所持有您的個資，本單位與相關申請本服務之場所單位會按照政府相關法規保密並予以妥善保管。<br>
                        7. 本單位基於蒐集目的而需蒐集、處理或利用您的個人資料，您可以自由選擇是否提供。 若您選擇不提供或是提供不完全時，將可能導致您無法進入室內場域使用服務，亦可能無法維護您的權益。<br>
                        8. 如拒絕填寫本表單或相同內容之紙本資料，並出示填寫後畫面，基於防疫需求，得拒絕您進入該場所。<br><br>

                        Speed Pass  Contact-Based Policy <br>
                        Announcement of rights and interests of personal data protection in response to epidemic prevention<br>
                        1. Special purpose "YOUNG SQUARE CREATECH" obtains your personal data based on the needs of the "COVID-19" epidemic prevention related business.<br>
                        2. The types of personal information collected includes name, mobile phone, etc., or other information that can directly or indirectly identify you, as detailed in the form.<br>

                        3. Use period, area, object and method<br>
                        (1) Period: The period of preservation required for the execution of the collection purpose to the end.<br>
                        (2) Area: The area of the Republic of China and the area where the objects that meet the purpose of the collection are located.<br>
                        (3) User: The operation units of the "SPEED PASS"  and "YOUNG SQUARE CREATECH".<br>
                        (4) Method of use: on the premise of not violating the purpose of the collection, use it through the Internet, e-mail, written, fax and other legal methods.<br>
                        4. You can contact your personal data to the  " YOUNG SQUARE CREATECH"in accordance with the Personal Data Protection Act<br>
                        (1) Request for an inquiry or reading.<br>
                        (2) Make a copy.<br>
                        (3) Request for addition or correction.<br>
                        (4) Request to stop collection, processing and utilization.<br>
                        (5) Request for deletion.<br>

                        5. If you want to exercise the rights in the preceding paragraph, please write to info@yscreatech.com<br>

                        6. Confidentiality of personal data<br>
                        For the operating unit of the "Speed Pass", namely the “YOUNG SQUARE CREATECH ", your personal data held by this unit will be kept confidential and properly kept in accordance with relevant government regulations.<br>
                        7. The unit needs to collect, process or use your personal data for the purpose of collection, you can choose whether to provide it or not. If you choose not to provide or provide incomplete, it may cause you to be unable to participate in, apply for related activities or plans, or the unit will not be able to provide you with complete services, and may not be able to protect your rights.<br>
                        8. If you refuse to fill out this form or paper materials with the same content, the unit may refuse you to enter the units based on the needs of epidemic prevention.
                    </p>
                </div>
            </div>

            <footer class="my-5 pt-5 text-muted text-small">
                <p class="mb-1">揚揚創新科技有限公司 YOUNG SQUARE CREATECH</p>
                <p class="mb-3">
                    Email: <a href="mailto:info@yscreatech.com?subject=我要回報">info@yscreatech.com</a>
                </p>
                <p class="mb-1">© 2014-2021 Young Square Createch LTD.</p>
            </footer>
            <div class="login_power">power by <span><img src="/images/img_logo_w.png"></span></div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import axios from 'axios'
    import UUID from 'vue-uuid'
    import moment from 'moment'
    import { ImmortalDB } from 'immortal-db'
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css'

    Vue.use(UUID)

    export default {
        name: "UserTrackingAct",
        async created() {
            this.locationSid = this.$route.params.locationSid
            let deviceFingerPrint = await ImmortalDB.get('YSCT_FINGER_PRINT_V2')
            if(deviceFingerPrint === null) {
                deviceFingerPrint = this.$uuid.v4()
                await ImmortalDB.set('YSCT_FINGER_PRINT_V2', deviceFingerPrint)
            } else {
                this.initialLoad(deviceFingerPrint)
            }
            this.deviceFingerPrint = deviceFingerPrint
            console.log("deviceFingerPrint = " + deviceFingerPrint)
            console.log("locationSid = " + this.$route.params.locationSid)
        },
        mounted() {
            let forms = document.getElementsByClassName('needs-validation')
            let validation = Array.prototype.filter.call(forms, function(form) {
                form.addEventListener('submit', function(event) {
                    if (form.checkValidity() === false) {
                        event.preventDefault();
                        event.stopPropagation();
                    }
                    form.classList.add('was-validated');
                }, false);
            })
        },
        data: function() {
            return {
                isUserMenuActive: false,
                status: 'NewUser',
                deviceFingerPrint: '',
                locationSid: '',
                userName: '',
                userPhoneNumber: '',
                locationName: '',
                locationAddress: '',
                locationPhoneNumber: '',
                attendance: '',
                isLoading: false,
                fullPage: true
            }
        },
        components: {
            Loading
        },
        methods: {
            moment: function () {
                return moment()
            },
            initialLoad: function(fingerPrint) {
                axios.post('https://jj7lnyhmk0.execute-api.ap-southeast-1.amazonaws.com/TEST/initialquery',
                    { fingerPrint: fingerPrint, locationSid: this.locationSid })
                    .then((res) => {
                        const result = JSON.parse(res.data.body)
                        if(result.MESSAGE === 'SUCCESS') {
                            if(result.USER_NAME !== '') {
                                this.isUserMenuActive = true
                                this.status = 'ExistingUser'
                            }
                            this.userName = result.USER_NAME
                            this.userPhoneNumber = result.USER_PHONE_NUMBER
                            this.locationName = result.LOCATION_NAME
                            this.locationAddress = result.LOCATION_ADDRESS
                            this.locationPhoneNumber = result.LOCATION_PHONE_NUMBER
                        }
                    })
                    .catch((error) => { console.error(error) })
            },
            enableUpdateMode: function() {
                this.status = 'UserProfileUpdate'
            },
            async submit() {
                if(this.userName.length !== 0 && this.userPhoneNumber.length !== 0 && this.attendance.length !== 0) {
                    this.isLoading = true
                    if (this.status === 'ExistingUser') {
                        this.addUserTrackingLog()
                    } else if (this.status === 'UserProfileUpdate') {
                        this.updateUserProfile()
                    } else {
                        this.deviceFingerPrint = this.deviceFingerPrint + "|" + this.userPhoneNumber
                        await ImmortalDB.set('YSCT_FINGER_PRINT_V2', this.deviceFingerPrint)
                        this.addUserProfile()
                    }
                } else {
                    console.log("Missed parameters")
                }
            },
            addUserProfile: function() {
                axios.post('https://jj7lnyhmk0.execute-api.ap-southeast-1.amazonaws.com/TEST/createuserprofile',
                    { fingerPrint: this.deviceFingerPrint, userName: this.userName, phoneNumber: this.userPhoneNumber })
                    .then((res) => {
                        const result = JSON.parse(res.data.body)
                        if(result.MESSAGE === 'SUCCESS') {
                            this.addUserTrackingLog()
                        }
                    })
                    .catch((error) => { console.error(error) })
            },
            updateUserProfile: function() {
                axios.post('https://jj7lnyhmk0.execute-api.ap-southeast-1.amazonaws.com/TEST/updateuserprofile',
                    { fingerPrint: this.deviceFingerPrint, userName: this.userName, phoneNumber: this.userPhoneNumber })
                    .then((res) => {
                        const result = JSON.parse(res.data.body)
                        if(result.MESSAGE === 'SUCCESS') {
                            this.addUserTrackingLog()
                        }
                    })
                    .catch((error) => { console.error(error) })
            },
            addUserTrackingLog : function() {
                axios.post('https://jj7lnyhmk0.execute-api.ap-southeast-1.amazonaws.com/TEST/addusertrackinglog',
                    { fingerPrint: this.deviceFingerPrint, locationSid: this.locationSid, attendance: this.attendance })
                    .then((res) => {
                        const result = JSON.parse(res.data.body)
                        if(result.MESSAGE === 'SUCCESS') {
                            this.redirect()
                        }
                    })
                    .catch((error) => { console.error(error) })
            },
            redirect: function() {
                this.$router.push({
                    name: 'UserTrackingResult',
                    params: {userName: this.userName, locationName: this.locationName, attendance: this.attendance}
                })
            }
        }
    }
</script>

<style scoped>

</style>
